@import "../../_globalColor";

.publication-card {
  background-color: $lightBackground2;
  box-shadow: $lightBoxShadow;
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 35px;
  transition: all 0.3s ease;
  border-left: 4px solid $buttonColor;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
    box-shadow: $lightBoxShadowDarker;
  }
}

/* Remove specific styling for individual cards */
/* Standardize title height for all cards */
.publication-card-title {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  height: 80px; /* Fixed height for title section */
  display: flex;
  align-items: center;
}

.dark-mode .publication-card-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.publication-title {
  color: $textColor;
  font-size: 24px;
  margin: 0; /* Remove margin to standardize height */
  font-weight: 700;
  line-height: 1.3;
}

.dark-mode {
  .publication-card {
    background-color: $darkBackground;
    box-shadow: $darkBoxShadow;
    border-left: 4px solid $buttonColor;

    &:hover {
      box-shadow: $darkBoxShadow2;
    }
  }

  .publication-title {
    color: $textColorDark;
  }

  .publication-authors,
  .publication-venue,
  .publication-abstract {
    color: $textColorDark;
  }
}

.publication-authors {
  color: $textColor;
  font-size: 16px;
  margin-bottom: 12px;
  font-style: italic;
}

.publication-venue {
  color: $textColor;
  font-size: 16px;
  margin-bottom: 20px;

  .publication-venue-name {
    font-weight: 600;
  }

  .publication-year {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 3px 8px;
    border-radius: 4px;
    margin-left: 8px;
    font-size: 14px;
  }
}

.dark-mode .publication-year {
  background-color: rgba(255, 255, 255, 0.1);
}

.publication-abstract {
  color: $cardSubtitle;
  font-size: 16px;
  margin-bottom: 25px;
  line-height: 1.6;
  text-align: justify;
  flex-grow: 1;
}

.publication-card-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: auto;
}

.publication-btn {
  text-decoration: none;
  color: $buttonColor;
  background-color: $lightBackground3;
  padding: 10px 18px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: $buttonHover;
    color: $textColorDark;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  &::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &[href*="arxiv"] {
    &::before {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="%23B31B1B" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1.99 6H7V7h10.01v2zm0 4H7v-2h10.01v2zm-3 4H7v-2h7.01v2z"/></svg>');
    }
  }

  &[href*="github"] {
    &::before {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="%23333" d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/></svg>');
    }
  }

  &[href*="huggingface"] {
    &::before {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="%23FFBD59" d="M10.646 16.619c0 .585-.483 1.06-1.078 1.06-.594 0-1.077-.475-1.077-1.06 0-.586.483-1.061 1.077-1.061.595 0 1.078.475 1.078 1.06zm4.91 0c0 .585-.484 1.06-1.078 1.06-.595 0-1.078-.475-1.078-1.06 0-.586.483-1.061 1.078-1.061.594 0 1.077.475 1.077 1.06zm-7.86-5.84c-1.189 0-2.155.96-2.155 2.139 0 1.18.966 2.139 2.155 2.139 1.19 0 2.156-.96 2.156-2.139 0-1.18-.967-2.139-2.156-2.139zm7.861 0c-1.19 0-2.156.96-2.156 2.139 0 1.18.967 2.139 2.156 2.139 1.19 0 2.156-.96 2.156-2.139 0-1.18-.967-2.139-2.156-2.139zm-7.861-1.568c1.19 0 2.156-.96 2.156-2.139 0-1.18-.967-2.139-2.156-2.139-1.189 0-2.155.96-2.155 2.139 0 1.18.966 2.139 2.155 2.139zm7.861 0c1.19 0 2.156-.96 2.156-2.139 0-1.18-.967-2.139-2.156-2.139-1.19 0-2.156.96-2.156 2.139 0 1.18.967 2.139 2.156 2.139z"/></svg>');
    }
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .publication-card-title {
    height: 70px; /* Adjust height for smaller screens */
  }

  .publication-title {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .publication-card-title {
    height: auto; /* Allow flexible height on mobile */
    min-height: 60px;
  }

  .publication-title {
    font-size: 20px;
  }
}
