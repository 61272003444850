@import "../../_globalColor";

.footer-text {
  text-align: center;
  color: $subTitle !important;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.dark-mode {
  color: $textColorDark !important;
}

.footer-div {
  margin-top: 4rem;
  padding: 2rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background-color: $lightBackground1;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dark-mode .footer-div {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  background-color: $darkBackground;
}

.footer-links {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.footer-social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: $lightBackground2;
  color: $buttonColor;
  font-size: 1.2rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: $buttonColor;
    color: white;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
}

.dark-mode .footer-social-link {
  background-color: $darkBackground;
  color: $textColorDark;

  &:hover {
    background-color: $buttonColor;
    color: white;
  }
}
