@import "../../_globalColor";

.education-card {
  position: relative;
  background-color: $lightBackground2;
  box-shadow: $lightBoxShadow;
  border-radius: 12px;
  border-left: 4px solid $buttonColor;
  transition: all 0.3s ease;
  margin-bottom: 30px;
  padding: 20px;
  height: auto; /* Changed from fixed height to auto */
  min-height: 380px; /* Set a minimum height */
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: $lightBoxShadowDarker;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
    background: linear-gradient(
      135deg,
      transparent 70%,
      rgba($buttonColor, 0.1) 100%
    );
    border-radius: 0 0 0 100%;
    z-index: 0;
  }
}

.dark-mode .education-card {
  background-color: $darkBackground;
  box-shadow: $darkBoxShadow;

  &:hover {
    box-shadow: $darkBoxShadow2;
  }

  &::before {
    background: linear-gradient(
      135deg,
      transparent 70%,
      rgba($buttonColor, 0.15) 100%
    );
  }
}

.education-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  height: 80px; /* Fixed height for header section */
}

.dark-mode .education-card-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.education-roundedimg {
  object-fit: cover;
  width: 5rem;
  height: 5rem;
  box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  margin-right: 15px;
  transition: transform 0.3s ease;

  .education-card:hover & {
    transform: scale(1.05);
  }
}

.education-header-text {
  flex: 1;
}

.education-text-school {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  color: $textColor;
  line-height: 1.3;
}

.dark-mode .education-text-school {
  color: $textColorDark;
}

.education-text-subHeader {
  color: $textColor;
  font-weight: 600;
  font-size: 18px;
  margin: 0px;
  padding-top: 0.5rem;
  line-height: normal;
}

.dark-mode .education-text-subHeader {
  color: $textColorDark;
}

.education-text-duration {
  display: inline-block;
  color: $lightBackground1;
  background-color: $buttonColor;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 10px;
  border-radius: 15px;
  margin-top: 5px;
  margin-bottom: 15px;
  line-height: 1.2;
}

.education-text-desc {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  color: $cardSubtitle;
  font-size: 15px;
  line-height: 1.5;
  text-align: justify;
}

.dark-mode .education-text-desc {
  color: $textColorDark;
}

.education-text-bullets {
  flex-grow: 0; /* Changed from 1 to 0 to prevent stretching */
}

.education-text-bullets > ul {
  margin-top: 0.25rem;
  padding-left: 20px;
  margin-bottom: 0;
}

.education-text-bullets > ul > li {
  color: $cardSubtitle;
  font-size: 15px;
  line-height: 1.4;
  margin-bottom: 8px;
  text-align: left;
  position: relative;

  strong {
    font-weight: 700;
    color: $textColor;
  }
}

.dark-mode .education-text-bullets > ul > li strong {
  color: $textColorDark;
}

/* Remove the border that was used in the old design */
.education-card-border {
  display: none;
}

/* Media Query */

@media (max-width: 1380px) {
  .education-card {
    min-height: 400px; /* Adjusted minimum height */
  }

  .education-card-header {
    height: 70px; /* Adjust height for smaller screens */
  }

  .education-text-school {
    font-size: 20px;
  }

  .education-text-subHeader {
    font-size: 16px;
  }

  .education-roundedimg {
    width: 4.5rem;
    height: 4.5rem;
  }
}

@media (max-width: 992px) {
  .education-card {
    min-height: 420px; /* Adjusted minimum height */
  }
}

@media (max-width: 768px) {
  .education-card {
    height: auto;
    min-height: 440px; /* Adjusted minimum height */
  }

  .education-card-header {
    flex-direction: column;
    text-align: center;
    height: auto; /* Allow flexible height on mobile */
    min-height: 60px;
    padding-bottom: 15px;
  }

  .education-roundedimg {
    width: 5rem;
    height: 5rem;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .education-text-bullets > ul {
    padding-left: 20px;
    text-align: left;
  }

  .education-text-school {
    font-size: 18px;
  }
}
