@import "../../_globalColor";

.projects-main-div {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 0px;
  margin: 0px auto;
  margin-top: 4rem;
}

.projects-header {
  text-align: center;
  margin-bottom: 40px;
}

.projects-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.projects-subtitle {
  color: $textColor;
  font-size: 20px;
  line-height: 30px;
  margin: 10px 0px;
}

.dark-mode.projects-subtitle {
  color: $textColorDark;
}

.card-image {
  max-width: 100%;
  height: auto;
  width: 250px;
}

.card-title {
  color: #000000;
  font-size: 26px;
  line-height: 1.3;
  font-weight: 700;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  height: 80px;
  display: flex;
  align-items: center;
}

.dark-mode .card-title {
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.card-subtitle {
  color: $cardSubtitle;
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 20px;
  overflow: auto;
  flex-grow: 1;
  max-height: 200px;
}

.project-card {
  display: flex;
  flex-direction: column;
  background-color: $lightBackground2;
  padding: 30px;
  border-radius: 12px;
  border-left: 4px solid $buttonColor;
  justify-content: space-between;
  height: auto;
  min-height: 450px;
  transition: transform 0.3s ease;
  box-shadow: $lightBoxShadow;
  margin-bottom: 35px;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
}

.project-card-light {
  background-color: $lightBackground2;

  &:hover {
    transform: translateY(-5px);
  }
}

.project-card-dark {
  background-color: $darkBackground;
  box-shadow: $darkBoxShadow;
  border-left: 4px solid $buttonColor;

  &:hover {
    transform: translateY(-5px);
  }
}

.project-detail {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.project-image {
  position: relative;
  height: 120px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: rgba($buttonColor, 0.05);
}

.project-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $buttonColor;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  box-shadow: 0 5px 15px rgba($buttonColor, 0.3);
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 2.5rem;

  /* Add animation for cards when they come into view */
  .fade-in {
    animation: fadeInUp 0.6s ease-out forwards;
  }
}

/* Add keyframes for the fade-in animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.project-card-footer {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.project-card-footer span.project-tag {
  text-decoration: none;
  color: $buttonColor;
  background-color: $lightBackground3;
  padding: 10px 18px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $buttonHover;
    color: $textColorDark;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
}

.dark-mode .project-tag {
  background-color: rgba(255, 255, 255, 0.1);
  color: $buttonColor;

  &:hover {
    background-color: $buttonHover;
    color: $textColorDark;
  }
}

@media (max-width: 1380px) {
  .projects-heading {
    font-size: 40px;
  }

  .projects-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  .project-card {
    min-height: 480px;
  }

  .card-title {
    font-size: 22px;
    height: 70px;
  }
}

@media (max-width: 992px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .project-card {
    min-height: 500px;
  }
}

@media (max-width: 768px) {
  .projects-heading {
    font-size: 30px;
    text-align: center;
  }

  .projects-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .projects-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .project-card {
    padding: 25px;
    height: auto;
    min-height: 400px;
  }

  .card-title {
    font-size: 20px;
    height: auto;
    min-height: 60px;
  }
}
