@import "../../_globalColor";

.experience-cards-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 2.5rem;
}

.experience-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.experience-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 0px;
  margin: 0px auto;
  margin-top: 4rem;
}

.experience-header {
  text-align: center;
  margin-bottom: 40px;
}

.experience-subtitle {
  color: $textColor;
  font-size: 20px;
  line-height: 30px;
  margin: 10px 0px;
}

.dark-mode.experience-subtitle {
  color: $textColorDark;
}

/* Animation for cards when they come into view */
.experience-cards-div .fade-in {
  animation: fadeInUp 0.6s ease-out forwards;
}

/* Keyframes for the fade-in animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Query */

@media (max-width: 1380px) {
  .experience-heading {
    font-size: 40px;
  }

  .experience-cards-div {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
}

@media (max-width: 992px) {
  .experience-cards-div {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .experience-heading {
    font-size: 30px;
    text-align: center;
  }

  .experience-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .experience-cards-div {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
