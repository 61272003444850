@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}

.main {
  margin-top: 2rem;
  margin-bottom: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (max-width: 768px) {
  .main {
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
