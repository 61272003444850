@import "../../_globalColor";

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}

.greet-main {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 0px;
  margin: 0px auto;
  margin-top: 2rem;
}

.greeting-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  background-color: $lightBackground2;
  border-radius: 12px;
  padding: 30px;
  box-shadow: $lightBoxShadow;
  transition: all 0.3s ease;
  border-left: 4px solid $buttonColor;

  &:hover {
    transform: translateY(-5px);
    box-shadow: $lightBoxShadowDarker;
  }
}

.dark-mode .greeting-main {
  background-color: $darkBackground;
  box-shadow: $darkBoxShadow;

  &:hover {
    box-shadow: $darkBoxShadow2;
  }
}

.greeting-text-div {
  width: 65%;
  text-align: left;
  padding-right: 0;
}

.greeting-image-div {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 10px 30px -15px $lightBoxShadowDark;
  border: 5px solid $lightBackground3;
  transition: all 0.3s ease;
}

.dark-mode .profile-image {
  border: 5px solid $darkBackground;
}

.subTitle {
  color: $subTitle !important;
}

.call-to-action {
  font-weight: 600;
  font-style: italic;
  margin-top: 10px;
  color: $textColor;
  font-size: 20px;
}

.dark-mode .call-to-action {
  color: $textColorDark;
}

.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.download-link-button {
  text-decoration: none;
}

.greeting-text {
  font-size: 45px;
  line-height: 1.1;
  color: $textColor !important;
  margin: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  height: 80px;
  display: flex;
  align-items: center;
}

.greeting-text-p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 15px;
  color: $cardSubtitle;
}

.dark-mode .greeting-text {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .greeting-text-p {
  color: $textColorDark;
}

.social-media {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

.button {
  background-color: $buttonColor;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: $buttonHover;
  box-shadow: 0 4px 12px $lightBoxShadow;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 38px;
    height: 70px;
  }
  .greeting-text-p {
    font-size: 17px;
    line-height: 28px;
  }
  .call-to-action {
    font-size: 17px;
  }
  .profile-image {
    width: 200px;
    height: 200px;
  }
  .greeting-main {
    padding: 25px;
  }
}

@media (max-width: 768px) {
  .greeting-text {
    font-size: 30px;
    text-align: center;
    height: auto;
    min-height: 60px;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .call-to-action {
    font-size: 16px;
  }
  .greeting-main {
    flex-direction: column-reverse;
    padding: 25px;
    gap: 20px;
  }
  .greeting-text-div {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
  .greeting-image-div {
    width: 100%;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
  }
  .button-greeting-div {
    justify-content: center;
  }
  .profile-image {
    width: 180px;
    height: 180px;
  }
  .social-media {
    justify-content: center;
  }
}

@media (max-width: 320px) {
  .profile-image {
    width: 150px;
    height: 150px;
  }
  .greeting-main {
    padding: 15px;
  }
}
